import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

export interface LoadingButtonProps {
  isLoading?: boolean;
}

export const LoadingButton: React.FC<LoadingButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  return (
    <button {...props} disabled={props.isLoading || props.disabled}>
      {props.isLoading ? <ClipLoader size={24} /> : props.children}
    </button>
  );
};

export default LoadingButton;
