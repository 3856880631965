import { apiClient } from '.';

import { PartnerModel } from '@models/auth';

export const getPartner = async (partnerId: string) => {
  return apiClient.get<PartnerModel>(`/app/v1/partners/${partnerId}`);
};

export const createMyFavorites = async (partnerId: string) => {
  return apiClient.post(`/app/v1/partners/${partnerId}/favorite`);
};

export const deleteMyFavorites = async (partnerId: string) => {
  return apiClient.delete(`/app/v1/partners/${partnerId}/favorite`);
};
