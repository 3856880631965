import * as feedApi from '@apis/app-feed';
import * as api from '@apis/app-user-contents';
import * as userApi from '@apis/app-user';
import { ReactComponent as LoungeSuggestionBanner } from '@assets/images/app/lounge-suggestion-banner.svg';
import { ReactComponent as StarOn } from '@assets/images/app/star-pink.svg';
import { ReactComponent as CheckIcon } from '@assets/images/editor/check-pink.svg';
import BottomRoundButton from '@components/common/BottomRoundButton';
import { URL_PATTERN } from '@components/Linkify/constants';
import LoungeEditor from '@components/LoungeEditor/LoungeEditor';
import LoungeEditorTopButton from '@components/LoungeEditor/LoungeEditorTopButton';
import { PRODUCT_ACTIVITY_TYPE_TEXT } from '@consts/products';
import { useAppNavigation } from '@hooks/appNavigation';
import { UserContentEditData, UserContentRateType } from '@models/user-contents';
import { useLoungeEditorModalStore } from '@stores/loungeEditorModalStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { removeTempProperties } from '@utils/dataTransform';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { extractFileName } from '@utils/string';
import { APP_BASE_PATH } from '@variables';
import { RcFile } from 'antd/es/upload';
import * as cheerio from 'cheerio';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import AppBottomModal from '../app/common/AppBottomModal';
import { Stars } from '../app/common/Stars';
import BottomSheet from './BottomSheet';

const DEFAULT_EDITOR_DATA = {
  board: 'review',
  rate1: 5,
  rate2: 5,
  rate3: 5,
  rate4: 5,
  text: '',
  originText: '',
  type: 'post',
  category: 'review',
};

export const LoungeEditorModal: React.FC = () => {
  const appNavigation = useAppNavigation();
  const dialog = useOkCancelDialog();
  const [openReviewStars, setOpenReviewStars] = useState<boolean>(false);
  const [openChangeCategory, setOpenChangeCategory] = useState<boolean>(false);
  const [openSuggestionBanner, setOpenSuggestionBanner] = useState<boolean>(true);

  const [canPost, setCanPost] = useState<boolean>(false);
  const { show, close, data } = useLoungeEditorModalStore();
  const [editorData, setEditorData] = useState<UserContentEditData>(DEFAULT_EDITOR_DATA);

  const userContentId = data?.userContentId;
  const paymentStatement = data?.paymentStatement;

  const getUserContentQuery = useQuery({
    queryKey: [`userContent-${userContentId}`],
    queryFn: () => api.getUserContent(userContentId!),
    enabled: !!userContentId,
  });

  const closeThisModal = () => {
    setCanPost(false);
    setEditorData(DEFAULT_EDITOR_DATA);
    close();
  };

  const updateUserContentMutation = useMutation({
    mutationFn: userApi.updateUserContent,
    onSuccess: async () => {
      closeThisModal();
      if (editorData.board === 'review') {
        dialog.open({
          title: '수정 완료!',
          content: '후기를 수정했습니다.',
          type: 'ok',
        });
      } else {
        dialog.open({
          title: '수정 완료!',
          content: '정상적으로 수정되었습니다.',
          type: 'ok',
        });
      }
      setCanPost(false);
    },
    onError: () => {
      setCanPost(true);
    },
  });

  const createUserContentMutation = useMutation({
    mutationFn: api.createUserContent,
    onSuccess: async (response) => {
      const userContentId = response.data?._id;
      const addedPoint = response.data.addedPoint;
      closeThisModal();
      if (editorData.board === 'review') {
        dialog.open({
          title: '작성 완료!',
          content: `${addedPoint?.toLocaleString()}포인트가 적립됐어요\n작성한 후기를 확인하시겠어요?`,
          type: 'ok_cancel',
          onConfirm: () => {
            appNavigation.to(`${APP_BASE_PATH}lounge/${userContentId}`);
          },
        });
      } else if (editorData.board === 'curation') {
        dialog.open({
          title: '작성 완료!',
          content: '소중한 콘텐츠를 제보해주셔서 감사합니다!',
          type: 'ok',
        });
      }
      setCanPost(false);
    },
    onError: () => {
      setCanPost(true);
    },
  });

  const userContent = getUserContentQuery.data?.data;

  const loungeCurationCategoriesQuery = useQuery({
    queryKey: [`lounge-curation-categories`],
    queryFn: () => feedApi.getLoungeCurationCategories(),
  });

  const loungeCurationCategories = loungeCurationCategoriesQuery.data?.data?.data || [];
  const loungeCurationCategoriesDict: { [key: string]: string | undefined } = {};
  loungeCurationCategories.forEach((item) => {
    loungeCurationCategoriesDict[item.type] = item.title;
  });

  useEffect(() => {
    if (userContent && show) {
      setEditorData({ ...userContent, ...userContent?.rates });
    }
  }, [userContent, show]);

  useEffect(() => {
    setEditorData((prev) => {
      return {
        ...prev,
        ...userContent?.rates,
        category: data?.category || prev.category,
        board: data?.board || prev.board,
      } as UserContentEditData;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (
      editorData &&
      JSON.stringify({ ...userContent, ...userContent?.rates }) !== JSON.stringify(editorData) &&
      JSON.stringify(DEFAULT_EDITOR_DATA) !== JSON.stringify(editorData)
    ) {
      if (!editorData.originText || editorData.originText === '' || editorData.originText === '<h1></h1>') {
        setCanPost(false);
      } else {
        setCanPost(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorData]);

  const onChangeImages = (images: string[], imagesTemp: string[]) => {
    setEditorData((data) => {
      return {
        ...data,
        images: images,
        imagesTemp: imagesTemp,
      };
    });
  };

  const onChangeImageFiles = async (files: FileList) => {
    const imageFiles: any = {};
    const newFiles = await Promise.all(
      Array.from(files).map(async (file) => {
        const result = await resizeImage(file, { maxWidth: 860 });
        const { fileName } = extractFileName(file.name);
        const contentTypes = result.type.split('/');

        const imageFile = new File([result.image], `${fileName}.${contentTypes[1]}`, { type: file.type }) as RcFile;
        const tempUrl = URL.createObjectURL(result.image);
        imageFiles[file.name] = imageFile;

        return { result, fileName: `${fileName}.${contentTypes[1]}`, imageFile, tempUrl };
      }),
    );

    const tempUrls: string[] = [];
    const fileNames: string[] = [];

    newFiles.forEach((newFile) => {
      tempUrls.push(newFile.tempUrl);
      fileNames.push(newFile.fileName);
    });

    setEditorData((data) => {
      return {
        ...data,
        files: { ...data.files, ...imageFiles },
        images: [...(data.images || []), ...fileNames],
        imagesTemp: [...(data.imagesTemp || data.images || []), ...tempUrls],
      };
    });
  };

  let title = '글쓰기';
  if (editorData?.board === 'review') {
    title = '후기 작성';
  } else if (editorData?.board === 'curation') {
    title = '콘텐츠 제보';
  }

  const suggestionHeight = loungeCurationCategories.length * 48 + 68;
  const doNotShowSuggestionBanner = localStorage.getItem('do-not-show-suggestion-banner');
  const showSuggestionBanner = editorData.board === 'curation' && openSuggestionBanner && !doNotShowSuggestionBanner;

  const product = userContent?.product || paymentStatement?.product;

  return (
    <AppBottomModal
      open={show && !!data}
      title={title}
      leftNaviButtonIcon={'close'}
      onLeftNaviButtonClick={() => {
        if (canPost) {
          dialog.open({
            title: '게시물 작성을 취소하고 나가시겠어요?',
            cancelText: '계속 작성',
            confirmText: '나가기',
            type: 'ok_cancel',
            onConfirm: () => {
              closeThisModal();
            },
          });
        } else {
          closeThisModal();
        }
      }}
      rightNaviButton={
        <div
          style={{
            color: canPost ? '#FF3D8F' : '#B9BBBE',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            padding: '0 8px',
          }}>
          {userContent ? '수정' : '등록'}
        </div>
      }
      onRightNaviButtonClick={() => {
        if (!canPost) {
          return;
        }
        if (editorData.board === 'review') {
          const dom = cheerio.load(editorData.text);
          const title = dom('h1').text();
          if (title.length > 30) {
            dialog.open({
              title: `제목이 너무 길어요.`,
              content: `30자 이내로 작성해주세요.\n지금은 ${title.length}자에요.`,
              type: 'ok',
            });
            return;
          }
          dom('h1').remove();
          const description = dom('body').text();
          if (description.length < 15) {
            dialog.open({
              title: '내용이 너무 짧아요',
              content: `15자 이상 작성해주세요\n지금은 ${description.length}자에요.`,
              type: 'ok',
            });
            return;
          }
        }
        if (editorData.board === 'curation') {
          const urlMatches = editorData.originText.match(URL_PATTERN);
          if (!urlMatches) {
            dialog.open({
              title: 'URL을 추가해주세요',
              content: '내용에는 URL이 반드시 포함되어야 합니다.\n예) https://www.naver.com',
              type: 'ok',
            });
            return;
          }
        }
        setCanPost(false);
        const formData: FormData = new FormData();
        const json = removeTempProperties(editorData);
        json['paymentStatementId'] = paymentStatement?._id;
        json['productId'] = paymentStatement?.product?.id;
        formData.append('json', JSON.stringify(json));

        if (editorData.files) {
          Object.values(editorData.files).forEach((file) => {
            formData.append('files', file);
          });
        }

        if (userContentId) {
          formData.append('userContentId', userContentId);
          updateUserContentMutation.mutate(formData);
        } else {
          createUserContentMutation.mutate(formData);
        }
      }}>
      {showSuggestionBanner && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background: 'rgba(0,0,0,0.7)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}>
          <LoungeSuggestionBanner style={{ display: 'block' }} />
          <div
            style={{
              color: 'var(--Neutral-70, #808387)',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              background: 'white',
              width: '335px',
              padding: '20px 16px',
              lineHeight: '20px',
            }}>
            <li>우수 콘텐츠로 선정될 경우, 콘텐츠로 발행됨과 동시에 1건당 3,000포인트가 적립됩니다.</li>
            <li style={{ marginTop: '8px' }}>1인당 매월 최대 30,000포인트까지 적립할 수 있습니다.</li>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '335px' }}>
            <button
              className="link-button"
              style={{
                color: '#FFF',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                height: '48px',
                padding: '0 16px',
              }}
              onClick={() => {
                localStorage.setItem('do-not-show-suggestion-banner', moment().format('YYYY-MM-DD HH:mm:ss'));
                setOpenSuggestionBanner(false);
              }}>
              다시 보지 않기
            </button>
            <button
              className="link-button"
              style={{
                color: '#FFF',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 700,
                height: '48px',
                padding: '0 16px',
              }}
              onClick={() => {
                setOpenSuggestionBanner(false);
              }}>
              닫기
            </button>
          </div>
        </div>
      )}
      <BottomSheet
        hideBackButton
        open={openChangeCategory}
        title={'주제'}
        height={suggestionHeight > 580 ? 580 : suggestionHeight}
        onClose={() => {
          setOpenChangeCategory(false);
        }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {loungeCurationCategories.map((item) => {
            const selected = item.type === editorData.category;
            return (
              <button
                key={item.type}
                className="link-button"
                style={{
                  padding: '0 20px',
                  color: selected ? '#FF3D8F' : 'var(--Neutral-90, #424242)',
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  textAlign: 'left',
                  height: '48px',
                }}
                onClick={() => {
                  setEditorData((prev) => {
                    return { ...prev, category: item.type };
                  });
                  setOpenChangeCategory(false);
                }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div>{item.title}</div>
                  <div>{selected && <CheckIcon />}</div>
                </div>
              </button>
            );
          })}
        </div>
      </BottomSheet>
      <BottomSheet
        hideBackButton
        open={openReviewStars}
        title={'후기 작성'}
        height={460}
        onClose={() => {
          setOpenReviewStars(false);
        }}>
        <div>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              width: '100%',
              minWidth: 0,
              overflow: 'hidden',
              padding: '8px 20px',
              borderTop: '1px solid #E8EAED',
              borderBottom: '1px solid #E8EAED',
            }}>
            <img
              src={cdnImageUrl(product?.thumbnailImage)}
              alt=""
              style={{
                height: '52px',
                width: '52px',
                objectFit: 'cover',
                borderRadius: '4px',
                display: 'bolck',
                flexShrink: 0,
              }}
            />
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minWidth: 0,
                overflow: 'hidden',
              }}>
              <div
                style={{
                  wordBreak: 'break-word',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  color: 'var(--Neutral-90, #424242)',
                  fontFamily: 'Pretendard',
                  fontSize: '13px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                }}>
                {product?.title}
              </div>
              {(product?.activityType || product?.favoritedCount) && (
                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    color: 'var(--Neutral-60, #9B9DA0)',
                    textAlign: 'right',
                    fontFamily: 'Pretendard',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    marginTop: '4px',
                  }}>
                  {product?.activityType && <div>{PRODUCT_ACTIVITY_TYPE_TEXT[product?.activityType]?.title}</div>}
                  {product?.activityType && <div style={{ width: '1px', height: '100%' }}></div>}
                  {product?.favoritedCount && product?.favoritedCount > 0 && (
                    <div>{`찜 ${product?.favoritedCount}개`}</div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              color: 'var(--Neutral-100, #242424)',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700,
              marginTop: '20px',
            }}>
            이 상품 어떠셨나요?
          </div>
          <div style={{ marginTop: '20px' }}>
            {Object.keys(editorData)
              .filter((key) => {
                if (key === 'rates') {
                  return false;
                }
                return key.startsWith('rate');
              })
              .map((key, index) => {
                const rateKeys = [
                  UserContentRateType.SOCIABILITY,
                  UserContentRateType.PROFESSIONALISM,
                  UserContentRateType.READINESS,
                  UserContentRateType.PUNCTUALITY,
                ];
                const value = editorData[key as 'rate1' | 'rate2' | 'rate3' | 'rate4'];

                return (
                  <div
                    key={key}
                    style={{
                      padding: '0 20px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: '10px',
                    }}>
                    <div
                      style={{
                        color: 'var(--Neutral-90, #424242)',
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        width: '60px',
                      }}>
                      {rateKeys[index]}
                    </div>
                    <Stars
                      size={24}
                      value={value}
                      onChange={(value: any) => {
                        setEditorData((prev) => {
                          const newValue = { ...prev } as any;
                          newValue[key] = value;
                          return newValue;
                        });
                      }}
                    />
                  </div>
                );
              })}
          </div>
          <div style={{ padding: '12px 20px' }}>
            <BottomRoundButton
              onClick={() => {
                setOpenReviewStars(false);
              }}>
              완료
            </BottomRoundButton>
          </div>
        </div>
      </BottomSheet>
      {editorData?.board === 'curation' && (
        <LoungeEditorTopButton
          style={{ borderTop: '1px solid #E8EAED', borderBottom: '1px solid #E8EAED', height: '52px' }}
          onClick={() => {
            setOpenChangeCategory(true);
          }}>
          <div
            style={{
              color: 'var(--Neutral-90, #424242)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
            }}>
            {loungeCurationCategoriesDict[editorData.category]}
          </div>
        </LoungeEditorTopButton>
      )}
      {editorData?.board === 'review' && (
        <LoungeEditorTopButton
          style={{
            borderTop: '1px solid #E8EAED',
            borderBottom: '1px solid #E8EAED',
            height: '68px',
          }}
          onClick={() => {
            setOpenReviewStars(true);
          }}>
          <div
            style={{
              height: '52px',
              display: 'flex',
              gap: '12px',
              width: '100%',
              minWidth: 0,
              overflow: 'hidden',
            }}>
            <img
              src={cdnImageUrl(product?.thumbnailImage || userContent?.product?.thumbnailImage)}
              alt=""
              style={{
                height: '52px',
                width: '52px',
                objectFit: 'cover',
                borderRadius: '4px',
                display: 'bolck',
                flexShrink: 0,
              }}
            />
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minWidth: 0,
                overflow: 'hidden',
              }}>
              <div
                style={{
                  wordBreak: 'break-word',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  color: 'var(--Neutral-90, #424242)',
                  fontFamily: 'Pretendard',
                  fontSize: '13px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                }}>
                {product?.title}
              </div>
              {(product?.activityType || product?.favoritedCount) && (
                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    color: 'var(--Neutral-60, #9B9DA0)',
                    textAlign: 'right',
                    fontFamily: 'Pretendard',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    marginTop: '4px',
                  }}>
                  {product?.activityType && <div>{PRODUCT_ACTIVITY_TYPE_TEXT[product?.activityType]?.title}</div>}
                  {product?.activityType && <div style={{ width: '1px', height: '100%' }}></div>}
                  {product?.favoritedCount && product?.favoritedCount > 0 && (
                    <div>{`찜 ${product?.favoritedCount}개`}</div>
                  )}
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
              }}>
              <div>
                <StarOn style={{ width: '24px' }} />
              </div>
              <div
                style={{
                  color: 'var(--Neutral-60, #9B9DA0)',
                  textAlign: 'right',
                  fontFamily: 'Pretendard',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                }}>
                {((editorData.rate1! + editorData.rate2! + editorData.rate3! + editorData.rate4!) / 4).toFixed(1)}
              </div>
            </div>
          </div>
        </LoungeEditorTopButton>
      )}
      <LoungeEditor
        board={editorData.board}
        value={editorData.originText}
        onChangeImageFiles={onChangeImageFiles}
        onChangeImages={onChangeImages}
        onChange={(html, originHtml) => {
          setEditorData((prev) => {
            const htmlText = html;
            const dom = cheerio.load(htmlText);
            const title = dom('h1').text();
            dom('h1').remove();
            const description = dom('body').text();

            return { ...prev, text: html, originText: originHtml, title, description };
          });
        }}
        attachFilesLimit={5}
        images={editorData.images}
        imagesTemp={editorData.imagesTemp}
      />
    </AppBottomModal>
  );
};
export default LoungeEditorModal;
