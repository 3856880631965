import { ProductModel } from '@models/product';
import { ProductCollectionSearchItem } from '@models/product-collection';
import { getUniqueKeyFromUUID } from '@utils/string';
import moment from 'moment';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AppUserActionData {
  actionType?: string;
  from?: string;
  to?: string;
  state?: any;
}

interface AppUserSessionStoreProps {
  sessionId: string;
  updatedAt: Date;
  step: number;
  actionData: AppUserActionData;
  sessionCollection: { [key: string]: ProductModel[] };
  sessionCollectionSearchItem: { [key: string]: ProductCollectionSearchItem };
  showLoungeNewBubble: boolean;
  currentLocation?: { err: number; latitude: number; longitude: number; time?: string };
  setCurrentLocation: (location?: { err: number; latitude: number; longitude: number; time?: string }) => void;
  setShowLoungeNewBubble: (show: boolean) => void;
  getSessionId: () => string;
  setActionData: (data: AppUserActionData) => void;
  setSessionCollection: (key: string, collectionProducts: ProductModel[]) => void;
  getSessionCollection: (key: string) => ProductModel[] | null;
  setSessionCollectionSearchItem: (key: string, item: ProductCollectionSearchItem) => void;
  getSessionCollectionSearchItem: (key: string) => ProductCollectionSearchItem | null;
}

export const useAppUserSessionStore = create(
  persist<AppUserSessionStoreProps>(
    (set, get) => ({
      sessionId: getUniqueKeyFromUUID(),
      step: 0,
      updatedAt: new Date(),
      sessionCollection: {},
      sessionCollectionSearchItem: {},
      setCurrentLocation: (location?: { err: number; latitude: number; longitude: number; time?: string }) => {
        set((state) => {
          return { ...state, currentLocation: location };
        });
      },
      getSessionCollection: (key: string) => {
        return get().sessionCollection[key];
      },
      setSessionCollection: (key: string, collectionProducts: ProductModel[]) => {
        set((state) => {
          return { ...state, sessionCollection: { ...state.sessionCollection, [key]: collectionProducts } };
        });
      },
      getSessionCollectionSearchItem: (key: string) => {
        return get().sessionCollectionSearchItem[key];
      },
      setSessionCollectionSearchItem: (key: string, item: ProductCollectionSearchItem) => {
        set((state) => {
          return { ...state, sessionCollectionSearchItem: { ...state.sessionCollectionSearchItem, [key]: item } };
        });
      },
      actionData: {},
      showLoungeNewBubble: true,
      setShowLoungeNewBubble: (show: boolean) => {
        set((state) => {
          return {
            ...state,
            showLoungeNewBubble: show,
          };
        });
      },
      setActionData: (data) => {
        set((state) => {
          return {
            ...state,
            actionData: data,
            step: state.step + 1,
          };
        });
      },
      getSessionId: () => {
        const currentDate = new Date();
        const updatedDate = get().updatedAt;
        let newSessionId = get().sessionId;
        if (moment(updatedDate).add(30, 'minutes') < moment(currentDate)) {
          newSessionId = getUniqueKeyFromUUID();
          set({
            sessionId: newSessionId,
            updatedAt: currentDate,
            step: 0,
            sessionCollection: {},
            showLoungeNewBubble: true,
          });
        }
        return newSessionId;
      },
    }),
    {
      name: 'igogo-app-session',
    },
  ),
);
