type EventFunctionKeys = 'navigation_back';
class EventManager {
  eventFunctions: { [key: string]: { [key: string]: any } } = {
    navigation_back: {},
  };

  addEventListener = (event: EventFunctionKeys, key: string, callback: (data: any) => void) => {
    this.eventFunctions[event][key] = callback;
  };

  removeEventListener = (event: EventFunctionKeys, key: string) => {
    try {
      delete this.eventFunctions[event][key];
      // eslint-disable-next-line no-empty
    } catch {}
  };
}

export const eventManager = new EventManager();
