import { ChildInfo } from '@models/auth';
import { Caution, TicketBackground, TicketContent } from './Components';
import { useQuery } from '@tanstack/react-query';
import { useAuthStore } from '@stores/authStore';
import * as api from '@apis/common';

export const CmathDiscountCoupon: React.FC = () => {
  const authStore = useAuthStore();
  const getMyEventRecordQuery = useQuery({
    queryKey: [`events-record-cmath_promo-${authStore.user?.id}`],
    queryFn: () => api.getMyEventRecord('cmath_promo'),
  });
  const data = getMyEventRecordQuery.data?.data?.data;

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <TicketBackground style={{ width: '100%', display: 'block' }} />
        <div style={{ position: 'absolute', bottom: '20px', left: 'calc(50% - 158px)' }}>
          <div style={{ position: 'relative' }}>
            <TicketContent />
            <div
              style={{
                position: 'absolute',
                left: '112px',
                top: '39px',
                color: 'var(--Neutral-90, #424242)',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '180%',
              }}>
              <div>{data?.eventNumber || 'ㅤ'}</div>
              <div>{data?.parentName || 'ㅤ'}</div>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  wordBreak: 'break-word',
                  whiteSpace: 'nowrap',
                  maxWidth: '180px',
                }}>
                {data?.children ? (data?.children || []).map((child: ChildInfo) => child.name).join(', ') : 'ㅤ'}
              </div>
              <div>2025. 4. 30. 까지</div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: 'var(--Neutral-20, #EFF1F4)' }}>
        <Caution style={{ width: '100%', display: 'block' }} />
      </div>
    </div>
  );
};

export default CmathDiscountCoupon;
