import { useAppUserSessionStore } from '@stores/appUserSessionStore';
import { useAuthStore } from '@stores/authStore';
import { getUserDeviceId } from '@utils/string';
import { useEffect } from 'react';
import { useAppSocket } from './appSocket';

export const useSessionLogger = () => {
  const sessionStore = useAppUserSessionStore();
  const authStore = useAuthStore();
  const deviceId = getUserDeviceId();
  const appSocket = useAppSocket();
  const path = window.location.pathname;

  useEffect(() => {
    appSocket.sendEvent({
      type: 'view',
      data: {
        deviceId: deviceId,
        sessionId: sessionStore.getSessionId(),
        product: 'app',
        step: sessionStore.step,
        userId: authStore.user?.id,
        path: path,
        actionData: sessionStore.actionData,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.step]);
};
