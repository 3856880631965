import { REVIEW_AVAILABLE_FROM } from '@consts/products';
import { Roles } from '@consts/role';
import { APP_STORE_LINK } from '@consts/url';
import { MessageActions } from '@hooks/appMessage';
import { compareVersion } from '@hooks/appPopup';
import { User } from '@models/auth';
import { Membership } from '@models/common';
import { ProductModel } from '@models/product';
import {
  PaymentStatement,
  PaymentStatementPaymentStatus,
  PaymentStatementPurchase,
  PaymentStatementRefundStatus,
} from '@models/purchase';
import {
  ADD_CLASS_GOOGLE_FORM_URL,
  B2B_URL,
  COMPANY_INFO_URL,
  CONTACT_URL,
  NATIVE_APP_SCHEME,
  SHARE_BASE_URL,
} from '@variables';
import moment from 'moment-timezone';
import { isMacOs, isWindows } from 'react-device-detect';
import { toast } from 'react-toastify';
import * as cheerio from 'cheerio';

export interface ShareOptions {
  title?: string;
  type: string; // product, collection, reward, partner, user-content
  id?: string;
  user?: User;
}

export interface NativeMessage {
  type: MessageActions;
  data: object;
}

export let globalTimer = undefined;

export const setGlobalTimer = (timer?: any) => {
  globalTimer = timer;
};

export const getNativeAppInfoFromUserAgent = (): {
  isNativeApp: boolean;
  appVersion: string | undefined;
  appPlatform: 'ios' | 'android' | undefined;
} => {
  const isNativeApp = /igogo-react-native-app/i.test(navigator.userAgent);
  let appPlatform: 'ios' | 'android' | undefined = undefined;
  let appVersion: string | undefined = undefined;
  if (isNativeApp) {
    if (navigator.userAgent.endsWith('ios')) {
      appPlatform = 'ios';
    } else if (navigator.userAgent.endsWith('android')) {
      appPlatform = 'android';
    }
    const regex = /igogo-react-native-app\/(\S+)/;
    const match = navigator.userAgent.match(regex);
    if (match) {
      appVersion = match[1];
    }
  }
  return {
    isNativeApp,
    appVersion,
    appPlatform,
  };
};

export const sendMessageToNative = (message: NativeMessage) => {
  return (window as any).ReactNativeWebView?.postMessage(JSON.stringify(message));
};

export const share = (options: ShareOptions) => {
  const { isNativeApp } = getNativeAppInfoFromUserAgent();

  let url = `${SHARE_BASE_URL}`;

  if (options.id) {
    if (options?.type === 'collection') {
      url = `${SHARE_BASE_URL}/c/${options.id}`;
    } else if (options?.type === 'reward') {
      url = `${SHARE_BASE_URL}/invite?rc=${options?.id}`;
    } else if (options?.type === 'partner') {
      url = `${SHARE_BASE_URL}/p/${options.id}`;
    } else if (options?.type === 'user-content') {
      url = `${SHARE_BASE_URL}/uc/${options.id}`;
    } else {
      url = `${SHARE_BASE_URL}/${options.id}`;
    }
  }

  let shareMessage = `아이고고 - 아이를 위한 모든 경험`;

  if (options.title) {
    shareMessage = `[아이고고] ${options.title || ''}`;
  }

  if (options?.type === 'reward') {
    if (options?.user?.profile?.name) {
      shareMessage = `${options?.user?.profile?.name}님의 아이고고 초대장입니다. 초대장을 통해 가입하면, 친구도 나도 3,000포인트 즉시 적립!`;
    } else {
      shareMessage = `아이고고 초대장입니다. 초대장을 통해 가입하면, 친구도 나도 3,000포인트 즉시 적립!`;
    }
  }

  if (isNativeApp) {
    const message = {
      type: MessageActions.SHARE_URL,
      data: {
        message: `${shareMessage}`,
        url,
      },
    };
    sendMessageToNative(message);
  } else {
    navigator.clipboard.writeText(url);
    toast.dark('공유 URL을 클립보드에 복사했습니다', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      pauseOnFocusLoss: false,
      draggable: true,
      progress: undefined,
      bodyStyle: {
        color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
        textAlign: 'center',
        fontFamily: 'Pretendard',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
      },
      theme: 'dark',
    });
  }
};

export const isParentRole = (roles?: Roles[]) => {
  return (roles || []).includes(Roles.PARENT);
};

export const setPushAlarmAgree = (userData: User | undefined) => {
  if (userData) {
    const isParent = userData.role === 'parent';
    const isPushAgree = userData.profile?.otherAlarmAgree || false;
    let messageType = isParent ? MessageActions.ACCPET_PARENT_ETC_PUSH : MessageActions.ACCPET_TUTOR_ETC_PUSH;
    if (!isPushAgree) {
      messageType = isParent ? MessageActions.DENY_PARENT_ETC_PUSH : MessageActions.DENY_TUTOR_ETC_PUSH;
    }
    const message = {
      type: messageType,
      data: {},
    };
    sendMessageToNative(message);
  } else {
    const message = {
      type: MessageActions.DENY_ETC_PUSH,
      data: {},
    };
    sendMessageToNative(message);
  }
};

export const deleteFilterUndefined = <T>(value: T | undefined): value is T => !!value;

export const clearEmptyKeysFromObject = (obj: object) => {
  const shallowCopyObject = { ...obj };

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'undefined') delete shallowCopyObject[key as keyof typeof shallowCopyObject];
  }

  return shallowCopyObject;
};

export const downloadImageFilesOnNativeApp = (images: string[]) => {
  const response = {
    type: MessageActions.DOWNLOAD_IMAGES,
    data: {
      images,
    },
  };
  sendMessageToNative(response);
};

export const openLink = (url: string) => {
  const { isNativeApp, appVersion } = getNativeAppInfoFromUserAgent();
  const hasParameter = !!url?.split('?')?.[1];
  const isConfigUrl = [COMPANY_INFO_URL, CONTACT_URL, B2B_URL, ADD_CLASS_GOOGLE_FORM_URL].includes(url);
  let canOpenInAppBrowser = isNativeApp && appVersion && !url?.startsWith('tel');
  if (hasParameter && !isConfigUrl) {
    const isUpdateRequired = compareVersion(appVersion, '2.2.9');
    canOpenInAppBrowser = isNativeApp && appVersion && !url?.startsWith('tel') && !isUpdateRequired;
  }

  if (canOpenInAppBrowser) {
    const postMessage = {
      type: MessageActions.SEND_INAPP_BROWSER,
      data: {
        url: url,
      },
    };
    sendMessageToNative(postMessage);
  } else {
    window.open(url, '_blank');
  }
};

export const openLinkInOsBrowser = (url: string) => {
  const { isNativeApp, appVersion, appPlatform } = getNativeAppInfoFromUserAgent();
  const isUpdateRequired = compareVersion(appVersion, '2.3.4');

  const canOpenInAppBrowser = isNativeApp && appVersion && !isUpdateRequired;

  if (canOpenInAppBrowser) {
    const postMessage = {
      type: MessageActions.SEND_OS_BROWSER,
      data: {
        url: url,
      },
    };
    sendMessageToNative(postMessage);
    return 'OK';
  } else {
    if (isNativeApp && appPlatform === 'ios') {
      return 'NEED_APP_UPDATE';
    } else {
      window.open(url, '_blank');
      return 'OK';
    }
  }
};

export const goToAppStore = () => {
  const { isNativeApp, appPlatform } = getNativeAppInfoFromUserAgent();
  if (isNativeApp && appPlatform) {
    const storeUrl = APP_STORE_LINK[`${appPlatform}`];
    window.location.href = storeUrl;
  }
};

export const getMobileWebPlatform = () => {
  let mobilePlatform: 'android' | 'ios' | undefined = undefined;
  if (navigator.userAgent.match(/Android/i)) {
    mobilePlatform = 'android';
  } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    mobilePlatform = 'ios';
  }
  return mobilePlatform;
};

export const openInNativeApp = (path: string) => {
  const { isNativeApp } = getNativeAppInfoFromUserAgent();
  const mobileWebPlatform = getMobileWebPlatform();
  const appSchemePath = `${NATIVE_APP_SCHEME}${path}`;
  let storePath: string | undefined = undefined;
  if (path && !isNativeApp) {
    if (mobileWebPlatform) {
      storePath = `${APP_STORE_LINK[`${mobileWebPlatform}`]}`;
      window.location.href = appSchemePath;
    } else {
      if (isMacOs) {
        storePath = `${APP_STORE_LINK[`ios`]}`;
      } else if (isWindows) {
        storePath = `${APP_STORE_LINK[`android`]}`;
      }
    }

    if (storePath) {
      setTimeout(() => {
        window.location.href = storePath!;
      }, 200);
    }
  }
};

export const getPlannedDateFromPaymentStatementItem = (
  saleType: string,
  item: PaymentStatementPurchase,
  paidDate: Date = new Date(),
): Date | undefined => {
  const regexClassSchedule = /\d{1,2}-\d{1,2}-\d{1,2}/;
  const thisYear = new Date(paidDate).getFullYear();

  if (saleType === 'commerce') {
    let selectedOptions = [];
    if (item.option && item.option.length > 0) {
      selectedOptions = item.option;
    } else {
      if (!Array.isArray(item.name)) {
        selectedOptions = item.name.split(' / ');
      } else {
        selectedOptions = item.name;
      }
    }

    const { matchDay, matchTime } = extractDateFromStrings(selectedOptions);

    if (matchDay) {
      const monthAndDay = matchDay.replace('월', '/').replace('일', '');
      let time = '';
      if (matchTime) {
        time = matchTime.replace('시', ':').replace('분', '');
        if (time.slice(-1) === ':') {
          time = time + '00';
        }
      }
      const monthAndDayTime = `${monthAndDay}${time ? `,${time}` : ''}`;
      const currentYear = new Date().getFullYear(); // safari 호환
      const convertedDate = new Date(`${currentYear}/${monthAndDayTime}`);

      const isNextYearSchedule = moment(paidDate).format('MM-DD') > moment(convertedDate).format('MM-DD');
      const purchasedSchedule = new Date(`${isNextYearSchedule ? thisYear + 1 : thisYear}/${monthAndDayTime}`);

      const utcDate = dateToUtcDate(purchasedSchedule);

      return utcDate;
    }
  } else {
    const isStringTypeDate = (item.name as string).replace(/\s/g, '').match(regexClassSchedule);
    if (isStringTypeDate && isStringTypeDate.length > 0) {
      const classSchedule = new Date(item.name as string);
      const utcDate = dateToUtcDate(classSchedule);

      return utcDate;
    }
    return undefined;
  }
};

export const extractDateFromStrings = (
  array: string[] = [],
): {
  matchDay: string | undefined;
  matchTime: string | undefined;
  matches: string[];
  notMatches: string[];
} => {
  const regexDate = /\d+\/\d+/g;
  const regexDate2 = /\d{1,2}월\d{1,2}일/g;
  const regexDate3 = /\d{1,2}-\d{1,2}-\d{1,2}/;
  const regexTime = /\d{1,2}:\d{1,2}/;
  const regexTime2 = /\d{1,2}시\d{1,2}/;
  const regexTime3 = /\d{1,2}시/;

  let matchDay: string | undefined = undefined;
  let matchTime: string | undefined = undefined;
  const matches: string[] = [];
  const notMatches: string[] = [];

  array.forEach((dateTimeString: string) => {
    let matched = false;
    const checkDate1 = dateTimeString.replace(/\s/g, '').match(regexDate);
    const checkDate2 = dateTimeString.replace(/\s/g, '').match(regexDate2);
    const checkDate3 = dateTimeString.replace(/\s/g, '').match(regexDate3);
    const checkTime1 = dateTimeString.replace(/\s/g, '').match(regexTime);
    const checkTime2 = dateTimeString.replace(/\s/g, '').match(regexTime2);
    const checkTime3 = dateTimeString.replace(/\s/g, '').match(regexTime3);

    if (checkDate1) {
      checkDate1.some((date) => {
        const currentYear = new Date().getFullYear(); // safari 호환
        const validFormattedDate = `${currentYear}/${date}`; // safari 호환
        if (isValidDateString(validFormattedDate)) {
          matched = true;
          matchDay = date;
          return true;
        }
        return false;
      });
    } else if (checkDate2) {
      checkDate2.some((date) => {
        const formattedDate = date.replace(/(\d{1,2})월(\d{1,2})일/, '$1/$2');
        const currentYear = new Date().getFullYear(); // safari 호환
        const validFormattedDate = `${currentYear}/${formattedDate}`; // safari 호환

        if (isValidDateString(validFormattedDate)) {
          matched = true;
          matchDay = formattedDate;
          return true;
        }
        return false;
      });
    } else if (checkDate3) {
      checkDate3.some((date) => {
        const currentYear = new Date().getFullYear(); // safari 호환
        const validFormattedDate = `${currentYear}-${date}`; // safari 호환
        if (isValidDateString(validFormattedDate)) {
          matched = true;
          matchDay = date;
          return true;
        }
        return false;
      });
    }

    if (checkTime1) {
      matched = true;
      matchTime = checkTime1[0];
    } else if (checkTime2) {
      matched = true;
      matchTime = checkTime2[0];
    } else if (checkTime3) {
      matched = true;
      matchTime = checkTime3[0];
    }

    if (!matched) {
      notMatches.push(dateTimeString);
    } else {
      matches.push(dateTimeString);
    }
  });

  return {
    matchDay,
    matchTime,
    matches,
    notMatches,
  };
};

export const isValidDateString = (value: string) => {
  try {
    const date = new Date(value);

    if (!date.getTime()) {
      return false;
    }
    return true;
  } catch {
    /* empty */
  }
  return false;
};

export const dateToUtcDate = (date: Date) => {
  return moment.tz(moment(date).format('YYYY-MM-DD HH:mm:ss'), 'Asia/Seoul').utc().toDate();
};

export const checkReviewSubmitCondition = (paymentStatement: PaymentStatement) => {
  let canCreate = false;
  let canEdit = false;
  let isWritablePaymentStatus = false;
  let isWritableRefundStatus = false;
  const today = new Date();
  const standardDate = new Date(REVIEW_AVAILABLE_FROM);
  const isPassedStandardDate =
    paymentStatement?.payment?.paidAt && new Date(paymentStatement.payment.paidAt) > standardDate;
  const refundStatus = (paymentStatement.statuses.refund || '').toLocaleLowerCase();
  const paymentStatus = (paymentStatement.statuses.payment || '').toLocaleLowerCase();
  const hasReview = !!paymentStatement.review;
  const hasPlannedDates = !!(
    paymentStatement.remainingPlannedDates && paymentStatement.remainingPlannedDates.length > 0
  );

  if (paymentStatus === PaymentStatementPaymentStatus.DONE) {
    isWritablePaymentStatus = true;
  }
  if (
    refundStatus === PaymentStatementRefundStatus.NONE ||
    refundStatus === PaymentStatementRefundStatus.DENIED ||
    refundStatus === PaymentStatementRefundStatus.REQUEST_CANCELLED ||
    (refundStatus === PaymentStatementRefundStatus.PARTIAL && hasPlannedDates)
  ) {
    isWritableRefundStatus = true;
  }
  let canWritablePeriod = false;
  if (hasPlannedDates) {
    const validTargetDate = new Date(paymentStatement.remainingPlannedDates![0]);
    const afterThreeMonth = new Date(validTargetDate.setMonth(validTargetDate.getMonth() + 3));
    if (new Date(paymentStatement.remainingPlannedDates![0]) < today && today < afterThreeMonth) {
      canWritablePeriod = true;
    }
  } else {
    const paidAt = paymentStatement.payment.paidAt;
    if (paidAt) {
      const validTargetDate = new Date(paidAt);
      const afterOneMonth = new Date(validTargetDate.setMonth(validTargetDate.getMonth() + 1));
      if (today < afterOneMonth) {
        canWritablePeriod = true;
      }
    }
  }

  if (canWritablePeriod && isPassedStandardDate && isWritablePaymentStatus && isWritableRefundStatus) {
    if (hasReview) {
      canEdit = true;
    } else {
      canCreate = true;
    }
  }

  return {
    canCreate,
    canEdit,
  };
};

export const discountedAmountBy = (amount: number, discountPercent: number) => {
  const result = ((amount || 0) * (100 - (discountPercent || 0))) / 100;
  return Math.floor(result / 10) * 10;
};

export const getAppliedMembership = (product?: ProductModel, userMembership?: Partial<Membership>) => {
  const productMemberships: Partial<Membership>[] = product?.memberships || [];
  const productId = product?._id;
  const appliedMembership = productMemberships.find((membership) => {
    return membership.alias === userMembership?.alias && userMembership?.qualified;
  });

  if (appliedMembership && productId) {
    const specialOfferProducts = appliedMembership.benefits?.specialOfferProducts || {};
    appliedMembership['discountPercent'] =
      specialOfferProducts[productId]?.discountPercent || appliedMembership.discountPercent;
  }

  return appliedMembership;
};

export const getNonDulplicatedArrayById = (originArr: any[], targetArr: any[]) => {
  const result = [...originArr];
  const originArrIds: string[] = [];
  (originArr || []).forEach((item) => {
    originArrIds.push(item?._id);
  });
  if (targetArr?.length > 0) {
    (targetArr || []).forEach((newContent) => {
      if (!originArrIds.includes(newContent?._id)) {
        result.push(newContent);
      }
    });
  }

  return result;
};

export const isTutorRole = (roles?: Roles[]) => {
  return (roles || []).includes(Roles.TUTOR);
};

export const htmlDecodeWithLineBreaks = (html: string) => {
  const breakToken = '_______break_______',
    lineBreakedHtml = html.replace(/<\/(.*?)>/gi, '</$1>' + breakToken);
  const result = cheerio.load(lineBreakedHtml).text().replace(new RegExp(breakToken, 'g'), '\n');
  return result;
};

const readyForGPS = async () => {
  return new Promise((resolve) => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        navigator.geolocation.clearWatch(watchId);
        resolve(true);
      },
      (err) => {
        resolve(false);
      },
      { enableHighAccuracy: true },
    );
  });
};

export const getCurrentLocation = async () => {
  const isReady = await readyForGPS();
  const { isNativeApp } = getNativeAppInfoFromUserAgent();
  const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

  if (isNativeApp) {
    const response = {
      type: MessageActions.REQUEST_LOCATION_PERMISSION,
      data: {},
    };
    sendMessageToNative(response);
  } else {
    if (permissionStatus.state === 'denied') {
      window.alert('브라우저 설정에서 위치정보 접근을 허용해주세요.');
    }
  }

  if (isReady) {
    return new Promise<{ err: number; latitude: number; longitude: number; time?: string }>((resolve, reject) => {
      if (navigator.geolocation) {
        const now = new Date();
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              err: 0,
              time: now.toLocaleTimeString(),
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (err) => {
            console.log('getCurrentLocation error', err);
            resolve({
              err: -1,
              latitude: -1,
              longitude: -1,
            });
          },
          { enableHighAccuracy: true, maximumAge: 2000, timeout: 5000 },
        );
      } else {
        reject({ err: -2, latitude: -1, longitude: -1 });
      }
    });
  } else {
    return { err: -3, latitude: -1, longitude: -1 };
  }
};

export const getDistanceBetweenGpsCoordinates = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres
  return d;
};

export const setNoticeRead = (noticeId: string) => {
  const noticeReads = localStorage.getItem('noticeReads');
  const noticeReadsArray = noticeReads ? JSON.parse(noticeReads) : [];
  noticeReadsArray.push(noticeId);
  localStorage.setItem('noticeReads', JSON.stringify(noticeReadsArray));
};

export const isNoticeRead = (noticeId: string) => {
  const noticeReads = localStorage.getItem('noticeReads');
  const noticeReadsArray = noticeReads ? JSON.parse(noticeReads) : [];
  return noticeReadsArray.includes(noticeId);
};
