import { LegacyLoginInfo } from '@hooks/appMessage';
import { UserMembership } from '@models/common';
import { apiClient } from '.';
import { AppleUser, JoinBody, KakaoUser, LoginParam, NaverUser, UpdateMeParam, User } from '../models/auth';

export const login = async (body: LoginParam) => {
  return apiClient.post('/auth/v1/login', body);
};

export const refresh = async () => {
  return apiClient.post('/auth/v1/refresh');
};

export const getMe = async () => {
  return apiClient.get<User>('/auth/v1/me');
};

export const getMyMembership = async () => {
  return apiClient.get<UserMembership>(`auth/v1/me/membership`);
};

export const deleteMyMembership = async () => {
  return apiClient.delete(`auth/v1/me/membership`);
};

export const updateMyMembership = async (alias: string) => {
  return apiClient.patch<User>(`auth/v1/me/membership`, { membershipAlias: alias });
};

export const deleteMe = async () => {
  return apiClient.delete('/auth/v1/me');
};

export const logout = async () => {
  return apiClient.post('/auth/v1/logout');
};

export const updateMyProfile = async (profileFormData: FormData) => {
  return apiClient.put('/auth/v1/me/profile', profileFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateMe = async (body: UpdateMeParam) => {
  return apiClient.put<User>('/auth/v1/me', body);
};

export const loginKakao = async (body: { code: string; [key: string]: any }) => {
  return apiClient.post<{ message: string; kakaoUser?: KakaoUser }>('/auth/v1/login/kakao', body);
};

export const loginNaver = async (body: { code: string; state: string; [key: string]: any }) => {
  return apiClient.post<{ message: string; naverUser?: NaverUser }>('/auth/v1/login/naver', body);
};

export const loginApple = async (body: { code: string; state: string; [key: string]: any }) => {
  return apiClient.post<{ message: string; appleUser?: AppleUser }>('/auth/v1/login/apple', body);
};

export const join = async (body: JoinBody) => {
  return apiClient.post('/auth/v1/join', body);
};

export const loginWithLegacyData = async (body: LegacyLoginInfo) => {
  return apiClient.post<{ message: string }>('auth/v1/login/legacy', body);
};
