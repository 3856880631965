import * as productsApi from '@apis/app-products';
import * as userApi from '@apis/app-user';
import ClassAddressView from '@components/app/class-invoice/ClassAddressView';
import ClassFeeView from '@components/app/class-invoice/ClassFeeView';
import { ClassInvoiceSummary } from '@components/app/class-invoice/ClassInvoiceSummary';
import Divider from '@components/app/class-invoice/Divider';
import BottomRoundButton from '@components/common/BottomRoundButton';
import { PAYMENT_METHODS_DICT, PaymentMethod } from '@consts/payment';
import { useAuthentication } from '@hooks/authentication';
import { usePayment } from '@hooks/payment';
import { ClassInvoice } from '@models/invoice';
import { CreatePaymentStatementsBody, PaymentStatementPaymentStatus } from '@models/purchase';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useQuery } from '@tanstack/react-query';
import { discountedAmountBy, getAppliedMembership, isParentRole } from '@utils/common';
import { getUniqueKeyFromUUID } from '@utils/string';
import { ENABLED_PAYMENT_METHOD } from '@variables';
import moment from 'moment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import './ClassInvoice.scss';
import PointBenefits from './PointBenefits';
import PointUse from './PointUse';
import SelectPaymentMethod from './SelectPaymentMethod';
import SelectTossPaymentMethod from './SelectTossPaymentMethod';

interface ClassInvoicePaymentProps {
  open?: boolean;
  classInvoice: ClassInvoice;
  isTutorLogin?: boolean;
  onCancel?: () => void;
  onPaymentCompleted?: () => void;
}

export const ClassInvoicePayment: React.FC<ClassInvoicePaymentProps> = (props) => {
  const { authStore, myMembership } = useAuthentication();
  const userPhone = authStore.user?.profile?.phone;
  const okCancelDialog = useOkCancelDialog();
  const tossPaymentWidgetRenderTimerRef = useRef<any>();
  const [paymentInProgress, setPaymentInProgress] = useState<boolean>(false);
  const payment = usePayment();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PAYMENT_METHODS_DICT.card);
  const [usedCreditPoint, setUsedCreditPoint] = useState<number>(0);
  const { classInvoice } = props;
  const selectedChildrenList = classInvoice?.students || [];
  const otherChildrenCount = classInvoice?.additionalParticipantCount || 0;
  const classThumbnail = classInvoice.classThumbnail;
  const parentId = classInvoice.parentId || classInvoice.userId;
  const parentUserQuery = useQuery({
    queryKey: [`user-${parentId}`],
    queryFn: () => userApi.getUser(parentId),
    enabled: !!parentId,
  });

  const productId = classInvoice.classId || classInvoice.programId;
  const classItemQuery = useQuery({
    queryKey: [`class-item-${productId}`],
    queryFn: () => productsApi.getProduct(productId!),
    enabled: !!productId,
  });

  const classProduct = classItemQuery.data?.data;
  const parentUser = parentUserQuery.data?.data;
  const userPoint = parentUser?.profile?.creditPoints || 0;
  const appliedMembership = getAppliedMembership(classProduct, myMembership);

  const totalFee = classInvoice.discountedAmount;
  const finalFee = discountedAmountBy(totalFee || 0, appliedMembership?.discountPercent || 0);

  useEffect(() => {
    if (tossPaymentWidgetRenderTimerRef.current) {
      clearTimeout(tossPaymentWidgetRenderTimerRef.current);
    }
    tossPaymentWidgetRenderTimerRef.current = setTimeout(() => {
      if (ENABLED_PAYMENT_METHOD === 'toss' && authStore.user?.id) {
        payment.renderTossPaymentsMethod(authStore.user?.id, Math.ceil(finalFee) - usedCreditPoint);
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.user, usedCreditPoint, props.open]);

  useEffect(() => {
    if (payment.status === 'done') {
      props.onPaymentCompleted && props.onPaymentCompleted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment.status]);

  let chidrenText = `${selectedChildrenList
    .map((child) => {
      return child.name;
    })
    .join(', ')}`;

  if (otherChildrenCount > 0) {
    chidrenText = `${selectedChildrenList
      .map((child) => {
        return child.name;
      })
      .join(', ')} 외 ${otherChildrenCount}명`;
  }

  const onClickPayment = () => {
    if (!userPhone) {
      okCancelDialog.open({
        title: '연락처 등록',
        content: '구매자 연락처는 필수 입력 정보입니다.',
        type: 'ok',
      });
      return;
    }

    if (!isParentRole(authStore.user?.roles)) {
      okCancelDialog.open({
        title: '구매 제한 안내',
        content: '부모 회원만 구매하실 수 있습니다.\n부모 회원 계정으로 로그인해주세요.',
        type: 'ok',
      });
      return;
    }

    if (paymentInProgress) {
      return;
    }
    setPaymentInProgress(true);
    setTimeout(() => {
      setPaymentInProgress(false);
    }, 5000);

    const purchases = classInvoice.schedules.map((schedule) => {
      return {
        name: moment(schedule.startDate).format('YYYY-MM-DD HH:mm'),
        fee: classInvoice?.classFeePerTime,
        count: selectedChildrenList.length + otherChildrenCount,
      };
    });

    const discountedFee = classInvoice.totalAmount - Math.ceil(totalFee);
    if (classInvoice.applyGroupDiscount && discountedFee > 0) {
      purchases.push({
        name: 'discount',
        fee: discountedFee,
        count: 1,
      });
    }

    const paymentStatement: CreatePaymentStatementsBody = {
      uuid: getUniqueKeyFromUUID(),
      seller: { id: classInvoice.tutorId, name: classInvoice.tutorName },
      buyer: {
        id: parentId!,
        name: parentUser?.profile?.name,
        phone: parentUser?.profile?.phone,
        membership: appliedMembership,
      },
      product: {
        saleType: 'class',
        id: classInvoice.classId || classInvoice.programId,
        name: `${classInvoice.classTitle} (${chidrenText})`,
        classInvoiceId: classInvoice._id,
        applyGroupDiscount: classInvoice.applyGroupDiscount,
      },
      purchases: purchases,
      payment: {
        method: ENABLED_PAYMENT_METHOD === 'toss' ? 'toss' : paymentMethod.type,
        gateway: ENABLED_PAYMENT_METHOD === 'toss' ? 'toss' : paymentMethod.paymentGateway,
        amount: Math.ceil(finalFee),
        point: usedCreditPoint,
        billingAmount: Math.ceil(finalFee) - usedCreditPoint,
        membershipDiscountAmount: totalFee - finalFee,
      },
      statuses: {
        payment: PaymentStatementPaymentStatus.READY,
      },
    };

    payment.requestPayment(paymentStatement);
  };

  const classFeePerTime = classInvoice.classFeePerTime || Math.ceil(finalFee) / 4 || classProduct?.fee;

  return (
    <OverlayScrollbarsComponent
      defer
      options={{
        scrollbars: { autoHide: 'scroll' },
        overflow: {
          x: 'hidden',
          y: payment.status === 'in_progress' ? 'hidden' : undefined,
        },
      }}
      style={{
        backgroundColor: '#ffffff',
        marginTop: 0,
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        width: '100%',
      }}>
      <div style={{ width: '100%' }}>
        <div style={{ padding: '20px 16px', backgroundColor: '#EFF1F4' }}>
          <ClassInvoiceSummary
            payment
            tutorProfileImage={classInvoice.tutorProfileImage}
            tutorName={classInvoice.tutorName}
            classTitle={classInvoice.classTitle}
            classThumbnail={classThumbnail}
            students={selectedChildrenList}
            additionalParticipantCount={otherChildrenCount}
            totalClassTimes={classInvoice.totalClassTimes}
            classDuration={classInvoice.classDuration}
            schedules={classInvoice.schedules}
          />
        </div>
        <ClassAddressView address={classInvoice.address} addressDetail={classInvoice.addressDetail} />
        <Divider />
        <PointUse
          userPoint={userPoint}
          totalFee={Math.ceil(finalFee)}
          productShippingFee={0}
          onChangeApplyPoint={(value) => {
            setUsedCreditPoint(value);
          }}
        />
        <Divider />
        {ENABLED_PAYMENT_METHOD === 'toss' && <SelectTossPaymentMethod />}
        {ENABLED_PAYMENT_METHOD === 'iamport' && (
          <SelectPaymentMethod
            onChange={(value) => {
              setPaymentMethod(value);
            }}
          />
        )}
        <Divider />

        <ClassFeeView
          defaultClassFee={classFeePerTime}
          totalClassTimes={classInvoice.totalClassTimes}
          totalStudentCount={selectedChildrenList.length + otherChildrenCount}
          applyGroupDiscount={classInvoice.applyGroupDiscount}
          usedPoint={usedCreditPoint || 0}
          appliedMembership={appliedMembership}
        />
        <Divider />
        <PointBenefits totalFee={totalFee} />
        <div style={{ padding: '24px 20px', backgroundColor: '#F5F7FB' }}>
          <div
            style={{
              color: 'var(--Neutral-70, #808387)',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
            }}>
            위 내용을 확인하였으며 결제에 동의합니다.
          </div>
          <BottomRoundButton
            onClick={() => {
              onClickPayment();
            }}
            style={{ marginTop: '40px' }}>
            {paymentInProgress ? <ClipLoader size={24} color={'#FFFFFF'}></ClipLoader> : '결제하기'}
          </BottomRoundButton>
        </div>
      </div>
    </OverlayScrollbarsComponent>
  );
};
