import { Roles } from '@consts/role';
import { Membership, UserMembership } from './common';
import { Academic, Career, Certificate } from './tutor';

export enum LoginType {
  EMAIL = 'email',
  KAKAO = 'kakao',
  NAVER = 'naver',
  APPLE = 'apple',
}

export interface Address {
  postcode?: string;
  postcode1?: string;
  postcode2?: string;
  postcodeSeq?: string;
  zonecode?: string;
  address?: string;
  addressEnglish?: string;
  addressType?: string;
  bcode?: string;
  bname?: string;
  bnameEnglish?: string;
  bname1?: string;
  bname1English?: string;
  bname2?: string;
  bname2English?: string;
  sido?: string;
  sidoEnglish?: string;
  sigungu?: string;
  sigunguEnglish?: string;
  sigunguCode?: string;
  userLanguageType?: string;
  query?: string;
  buildingName?: string;
  buildingCode?: string;
  apartment?: string;
  jibunAddress?: string;
  jibunAddressEnglish?: string;
  roadAddress?: string;
  roadAddressEnglish?: string;
  autoRoadAddress?: string;
  autoRoadAddressEnglish?: string;
  autoJibunAddress?: string;
  autoJibunAddressEnglish?: string;
  userSelectedType?: string;
  noSelected?: string;
  hname?: string;
  roadnameCode?: string;
  roadname?: string;
  roadnameEnglish?: string;
  address2?: string;
}

export interface UserProfile {
  name?: string;
  nickname?: string;
  message?: string;
  educationArea?: any;
  agreement?: boolean;
  homepageId?: string;
  educationTitle?: string;
  imageUrl?: string;
  phone?: string;
  positiveWeeks?: string[];
  favorites: string[];
  contract?: TutorContract;
  creditPoints?: number;
  coupons?: string[];
  otherAlarmAgree?: boolean;
  marketingEmailAgree?: boolean;
  marketingSMSAgree?: boolean;
  children?: ChildInfo[];
  address?: Address;
  categorySubList?: string[];
  customerServiceUrl?: string;
  customerServicePhone?: string;
  mainCustomerService?: string;
  salesChannels?: string[];
  active?: boolean;
}

export interface PartnerProfile {
  intro?: string; //greeting
  academics?: Academic[];
  careers?: Career[];
  certificates?: Certificate[];
  mainActivityZone?: { city: string; district: string }[];
  updatedAt?: Date;
}

export interface User {
  id: string;
  _id?: string;
  password?: string;
  email: string;
  loginType: LoginType;
  role: Roles;
  roles: Roles[];
  profile?: UserProfile;
  partnerProfile?: PartnerProfile;
  membership?: Partial<UserMembership>;
  rewardCode?: string;
  deletedAt?: Date;
  referral?: { type?: string; rewardCode?: string; eventCode?: string };
}

export interface PartnerModel extends User {
  likeCount?: number;
}

export interface LoginParam {
  email: string;
  password: string;
}

export type ChildInfoKeyType = 'name' | 'birthday' | 'gender';
export class ChildInfo {
  name?: string;
  birthday?: string;
  gender?: string;

  constructor(data: { name?: string; birthday?: string; gender?: string }) {
    this.name = data.name;
    this.birthday = data.birthday;
    this.gender = data.gender;
  }

  toString() {
    return `${this.name}-${this.birthday}-${this.gender}`;
  }
}

export interface TutorContract {
  contractType?: string;
  licensePhoto?: string;
  bankName?: string;
  bankAccountHolder?: string;
  bankAccountNumber?: string;
  bankAccountPhoto?: string;
  contractEmail?: string;
  businessName?: string;
  representative?: string;
  address?: any;
  businessNumber?: string;
}

export interface UpdatePasswordParam {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface UpdateMeParam {
  password?: UpdatePasswordParam;
  name?: string;
  phoneNumber?: string;
  address?: Address;
  categorySubList?: string[];
  contract?: TutorContract;
  children?: ChildInfo[];
  userId?: string;
}

export interface AppToken {
  token: string;
  os: string;
  appVersion?: string;
  iosOriginToken?: string;
}

export interface JoinBody {
  name?: string;
  nickname?: string;
  message?: string;
  phoneNumber?: string;
  profileImage?: string;
  role?: Roles;
  type?: 'email' | 'naver' | 'kakao' | 'apple';
  email?: string;
  naverId?: string;
  kakaoId?: string;
  appleId?: string;
  password?: string;
  address?: Address;
  marketingEmailAgree?: boolean;
  marketingSMSAgree?: boolean;
  token?: AppToken;
  /** parent only */
  child?: ChildInfo;
  referral?: {
    type?: string;
    rewardCode?: string;
    eventCode?: string;
  };
  /** tutor only */
  categorySubList?: string[];
  contractType?: string;
  membership?: Membership;
  [key: string]: any;
}

export interface KakaoToken {
  token_type: string;
  access_token: string;
  id_token?: string;
  expires_in: number;
  refresh_token: string;
  refresh_token_expires_in: number;
  scope?: string;
}

export interface KakaoUser {
  id: number;
  connected_at: string;
  properties: Properties;
  kakao_account: KakaoAccount;
}

export interface Properties {
  nickname: string;
  profile_image: string;
  thumbnail_image: string;
}

export interface KakaoAccount {
  profile_needs_agreement: boolean;
  profile: Profile;
  has_email: boolean;
  email_needs_agreement: boolean;
  is_email_valid: boolean;
  is_email_verified: boolean;
  email: string;
  name: string;
}

export interface Profile {
  nickname: string;
  thumbnail_image_url: string;
  profile_image_url: string;
  is_default_image: boolean;
  is_default_nickname: boolean;
}

export interface NaverUser {
  id: string;
  nickname: string;
  profile_image: string;
  email: string;
  name: string;
}

export interface AppleUser {
  id: string;
  email?: string;
}
