import * as api from '@apis/common';
import LoadingButton from '@components/app/common/LoadingButton';
import { useAuthentication } from '@hooks/authentication';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { openLinkInOsBrowser } from '@utils/common';
import React, { useState } from 'react';
import { Caution, CmathMap, NotionContent, Tickets } from './Components';
import { APP_STORE_URL } from '@variables';

export const CmathDiscountCouponEvent: React.FC<{
  hasRecordData?: boolean;
  isClosed?: boolean;
  onChangeView?: (view: string) => void;
}> = (props) => {
  const dialog = useOkCancelDialog();
  const { checkLogin } = useAuthentication();
  const [onLoading, setOnLoading] = useState<boolean>(false);
  const queryClient = new QueryClient();
  const createEventRecordMutation = useMutation({
    mutationFn: api.createEventRecord,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [`events-cmath_promo`] });
      setOnLoading(false);
      if (res.data.status === 'OK') {
        props.onChangeView?.('record');
      } else if (res.data.status === 'LIMIT_EXCEEDED') {
        dialog.open({
          type: 'ok',
          confirmColor: '#FF3D8F',
          title: '이벤트 마감 안내',
          content: `시매쓰에서 제공한 할인혜택이 선착순 마감되었습니다. 참여해주셔서 감사합니다.`,
        });
      } else {
      }
    },
    onError: () => {
      setOnLoading(false);
    },
  });
  return (
    <div>
      <NotionContent />
      <div style={{ position: 'relative' }}>
        <CmathMap style={{ width: '100%', display: 'block' }} />
        <button
          className="link-button"
          style={{
            position: 'absolute',
            bottom: '52px',
            left: '20px',
            right: '20px',
            display: 'flex',
            padding: '17px 20px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            background: 'var(--Neutral-100, #242424)',
            color: 'var(--White-100, #FFF)',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
          }}
          onClick={() => {
            const result = openLinkInOsBrowser('https://naver.me/5z5kTdZl');
            if (result === 'NEED_APP_UPDATE') {
              dialog.open({
                type: 'ok_cancel',
                confirmColor: '#FF3D8F',
                title: '앱 업데이트 필요',
                content: `이 기능을 사용하려면 앱스토어에서 앱을 업데이트해주세요.`,
                confirmText: '업데이트',
                onConfirm: () => {
                  window.open(APP_STORE_URL, '_blank');
                },
              });
            }
          }}>
          우리동네 시매쓰 학원 찾기
        </button>
      </div>
      <div style={{ position: 'relative' }}>
        <Tickets style={{ width: '100%', display: 'block' }} />
        {props.hasRecordData ? (
          <button
            onClick={() => {
              props.onChangeView?.('record');
            }}
            className="link-button"
            style={{
              position: 'absolute',
              bottom: '52px',
              left: '20px',
              right: '20px',
              display: 'flex',
              padding: '17px 20px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              background: 'var(--Neutral-100, #FF3D8F)',
              color: 'var(--White-100, #FFF)',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
            }}>
            나의 쿠폰정보 확인하기
          </button>
        ) : props.isClosed ? (
          <button
            className="link-button"
            style={{
              position: 'absolute',
              bottom: '52px',
              left: '20px',
              right: '20px',
              display: 'flex',
              padding: '17px 20px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              background: 'var(--Neutral-100, #D5D8DD)',
              color: 'var(--White-100, #FFF)',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
            }}>
            선착순 마감된 이벤트입니다
          </button>
        ) : (
          <LoadingButton
            isLoading={onLoading}
            className="link-button"
            style={{
              position: 'absolute',
              bottom: '52px',
              left: '20px',
              right: '20px',
              display: 'flex',
              padding: '17px 20px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              background: 'var(--Neutral-100, #FF3D8F)',
              color: 'var(--White-100, #FFF)',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
            }}
            onClick={async () => {
              if (await checkLogin(true)) {
                setOnLoading(true);
                createEventRecordMutation.mutate('cmath_promo');
              }
            }}>
            시매쓰 학원 수강료 할인권 받기
          </LoadingButton>
        )}
      </div>
      <div style={{ background: 'var(--Neutral-20, #EFF1F4)' }}>
        <Caution style={{ width: '100%', display: 'block' }} />
      </div>
    </div>
  );
};

export default CmathDiscountCouponEvent;
